import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://use.fieldwise.ai/api';

export interface QueryResponse {
  answer: string;
}

export const queryFieldwise = async (question: string, appName: string): Promise<QueryResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/query`, {
      text: question,
      app: appName
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.detail || 'An error occurred');
    }
    throw new Error('Failed to communicate with the server');
  }
}; 