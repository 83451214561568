import React, { useEffect, useState } from 'react';
import { Layout, Typography, ThemeConfig, ConfigProvider, theme as antdTheme } from 'antd';
import QueryInterface from './components/QueryInterface';

const { Header, Content } = Layout;
const { Title } = Typography;

const theme: ThemeConfig = {
  algorithm: antdTheme.darkAlgorithm,
  token: {
    fontFamily: `"SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    colorError: "#df2d0d",
    colorErrorHover: "#cf1322",
    colorSuccessHover: "#389e0d",
    colorLinkHover: "#3b9e0b",
    colorTextBase: "#ffffff",
    colorFill: "#2222221f",
    colorFillSecondary: "#2222220f",
    colorFillTertiary: "#2222220a",
    colorFillQuaternary: "#22222205",
    colorBorder: "#ffffff1f",
    colorBorderSecondary: "#ffffff3d",
    colorBgLayout: "#191c19",
    colorPrimary: "#49aa19",
    colorInfo: "#49aa19",
    colorPrimaryTextHover: "#42b609",
    borderRadius: 6,
    colorPrimaryHover: "#42b609",
    colorBgBase: "#191c19",
    colorBgElevated: "#10160c",
    colorBgContainer: "#060d02",
  },
  components: {
    Breadcrumb: {
      linkHoverColor: "rgba(61,18,18,0.88)",
    },
    Layout: {
      headerHeight: 54,
    },
    Badge: {
      colorError: "rgb(250,140,22)",
      colorErrorHover: "rgb(212,136,6)",
    },
  },
};

const App: React.FC = () => {
  const [appName, setAppName] = useState<string>('');

  useEffect(() => {
    // Get the app name from the URL path
    const pathSegments = window.location.pathname.split('/');
    const app = pathSegments[1] || '';
    
    if (!app) {
      // Redirect to /default if no app is provided
      window.location.href = '/default';
      return;
    }
    
    setAppName(app);

    // Update document title
    document.title = `${app.charAt(0).toUpperCase() + app.slice(1)} - Fieldwise Query System`;
  }, []);

  if (!appName) {
    return null; // Return null while redirecting
  }

  return (
    <ConfigProvider theme={theme}>
      <Layout style={{ minHeight: '100vh' }}>
        <Header 
          style={{ 
            background: theme.token?.colorBgContainer,
            padding: '0 24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={'/logo-new.png'} alt="Logo" style={{ height: '80px' }} />
          </div>
        </Header>
        <Content style={{ 
          padding: '24px',
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          background: theme.token?.colorBgBase
        }}>
          <QueryInterface appName={appName} />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default App; 