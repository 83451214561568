import React, { useState, ChangeEvent } from 'react';
import { Input, Button, Card, message, Spin } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { queryFieldwise } from '../services/api';
import { getAppConfig } from '../config/apps';

const { TextArea } = Input;

interface QueryInterfaceProps {
  appName: string;
}

const QueryInterface: React.FC<QueryInterfaceProps> = ({ appName }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const appConfig = getAppConfig(appName);

  const handleSubmit = async () => {
    if (!question.trim()) {
      message.warning('Please enter a question');
      return;
    }

    setLoading(true);
    try {
      const response = await queryFieldwise(question, appName);
      setAnswer(response.answer);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title={appConfig.interfaceTitle} style={{ maxWidth: 800, margin: '0 auto' }}>
      <div style={{ marginBottom: 16 }}>
        <TextArea
          rows={4}
          value={question}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setQuestion(e.target.value)}
          placeholder={appConfig.placeholder}
          disabled={loading}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={handleSubmit}
          loading={loading}
        >
          {appConfig.buttonText}
        </Button>
      </div>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : answer && (
        <Card type="inner" title="Answer">
          <ReactMarkdown>{answer}</ReactMarkdown>
        </Card>
      )}
    </Card>
  );
};

export default QueryInterface; 
