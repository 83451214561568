interface AppConfig {
  buttonText: string;
  interfaceTitle: string;
  placeholder?: string;
}

interface AppsConfig {
  [key: string]: AppConfig;
}

export const appsConfig: AppsConfig = {
  default: {
    buttonText: "Ask a Question",
    interfaceTitle: "Query Interface",
    placeholder: "Enter your question here..."
  },
  lawyer: {
    buttonText: "Talk to a Lawyer",
    interfaceTitle: "Legal Assistant",
    placeholder: "Ask your legal question here..."
  },
  tax: {
    buttonText: "Ask Tax Expert",
    interfaceTitle: "Tax Advisor",
    placeholder: "Ask your tax-related question here..."
  },
  medical: {
    buttonText: "Consult Doctor",
    interfaceTitle: "Medical Consultant",
    placeholder: "Ask your medical question here..."
  }
};

// Helper function to get config for an app
export const getAppConfig = (appName: string): AppConfig => {
  return appsConfig[appName] || appsConfig.default;
}; 